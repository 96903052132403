import styled from 'styled-components';
import { themeGet } from 'styled-system';
// import BannerBG from 'common/src/assets/image/agency/agency-banner.png';
import Mail from 'common/src/assets/image/app/mail.svg';

const BannerWrapper = styled.section`
  padding-top: 150px;
  padding-bottom: 160px;

  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  overflow: hidden;
  @media only screen and (min-width: 1367px) {
    min-height: 100vh;
  }
  @media (max-width: 990px) {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  @media only screen and (max-width: 480px) {
    background: none;
    padding-top: 130px;
    padding-bottom: 60px;
  }
  .particle {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 990px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }
  .row {
    position: relative;
    z-index: 1;
  }
  .button__wrapper {
    margin-top: 40px;
    .reusecore__button {
      &:first-child {
        transition: all 0.3s ease;
        &:hover {
          box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
        }
      }
    }
  }
  .datepicker{
    margin-bottom:20px;
    span{
      width:100%;
      margin-right:20px;
    }
    input{
      border-radius: 4px !important;
    }
  }
  .DateInput_4z7e5d{
    width:230px !important;
    border-radius: 4px !important;
    margin-left:10px;
  }
`;

const DiscountLabel = styled.div`
  display: inline-block;
  border-radius: 4em;
  padding: 7px 25px;
  box-shadow: 0px 4px 50px 0px rgba(22, 53, 76, 0.08);
  margin-bottom: 30px;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media (max-width: 767px) {
    padding: 7px 10px;
  }
`;
export const NameInputWrapper = styled.div`
  position: relative;
  width: 85%;
  @media (max-width: 768px) {
    width: 100%;
  }

  &::before {
    // content: url(${Mail});
    display: block;
    position: relative;
    width: 22px;
    left: 22px;
    top: 41px;
    z-index: 9;
  }
  input {
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.08) !important;
    border: 0;
    margin-bottom: 20px;
    height: 50px;
    padding-left: 60px !important;
    // color: #343d48;
    opacity: 1;
    font-weight: 500;
    @media (max-width: 768px) {
    }
    &:focus {
      border: 1px solid rgb(26, 115, 232);
      box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.08);
    }

    &:placeholder {
      font-size: 16px;
      color: #343d48;
      opacity: 1;
    }
  }
  .react-datepicker-wrapper{
    width:100%;
  }
  textarea {
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.08) !important;
    border: 0 ;
    margin-bottom: 30px;
    height: 50px;
    padding-left: 60px !important;
    color: #343d48;
    opacity: 1;
    font-weight: 500;
    @media (max-width: 768px) {
    }
    &:focus {
      border: 1px solid rgb(26, 115, 232);
      box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.08);
    }

    &:placeholder {
      font-size: 16px;
      color: #343d48;
      opacity: 1;
    }
  }
  .select__control,
    .select-field__wrapper {
      height: auto;
      margin-bottom :20px;
    }
    .reusecore__field-label{
      font-weight:500;
      font-size:16px;
    }
    .select__control {
      padding: 0 15px 0 10px;
      // box-shadow: none;
      position: relative;
      border:1px solid #ebebeb;
      @media (min-width: 576px) {
        border-color: #f4f5f7;
        &:before {
          content: '';
          position: absolute;
          width: 1px;
          // height: 55%;
          background: #d9d9d9;
          display: block;
          // top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      .select__placeholder {
        font-size: 17px;
        color: #0f2137;
      }
      .select__indicator {
        color: #0f2137;
      }
      .select__value-container {
        input{
          width:100%;
        }
      }
      
    }
    .select__indicator-separator {
      display: none;
    }
`;
export const SearchWrapper = styled.div`
  @media (min-width: 576px) {
    display: flex;
    align-items: stretch;
    height: 40px;
    box-shadow: 0px 6px 15.98px 1.02px rgba(56, 86, 122, 0.1);
    border-radius: 10px;
  }
  @media (max-width: 990px) and (min-width: 576px) {
    height: 65px;
  }
  .domain_search_input {
    flex-grow: 1;
    box-shadow: none;
    outline: none;
    .field-wrapper {
      border: 0;
    }
    .field-wrapper,
    input {
      height: 100%;
      box-shadow: none;
      outline: none;
    }
    input {
      font-size: 17px;
      padding-left: 50px;
      padding-right: 30px;
      text-shadow: none;
      border: 1px solid #ebebeb;
      &:focus {
        border-color: #ebebeb;
      }

      @media (min-width: 576px) {
        border-right: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-color: #f4f5f7;
        &:focus {
          border-color: #f4f5f7;
        }
      }
      @media (max-width: 575px) {
        height: 52px;
        padding-left: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .domain_search_select {
    min-width: 165px;
    @media (max-width: 575px) {
      height: 52px;
      margin-bottom: 20px;
    }
    .select__control,
    .select-field__wrapper {
      height: 100%;
    }
    .select__control {
      padding: 0 15px 0 10px;
      box-shadow: none;
      position: relative;
      border-color: #ebebeb;
      @media (min-width: 576px) {
        border-color: #f4f5f7;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        &:before {
          content: '';
          position: absolute;
          width: 1px;
          height: 55%;
          background: #d9d9d9;
          display: block;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      .select__placeholder {
        font-size: 17px;
        color: #0f2137;
      }
      .select__indicator {
        color: #0f2137;
      }
    }
    .select__indicator-separator {
      display: none;
    }
  }

  .domain_search_button {
    width: 160px;
    @media (min-width: 576px) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    @media (max-width: 575px) {
      height: 52px;
      width: 100%;
    }
  }
`;
export const EmailInputWrapper = styled.div`
  position: relative;
  width: 85%;
  @media (max-width: 768px) {
    width: 100%;
  }

  &::before {
    content: url(${Mail});
    display: block;
    position: relative;
    width: 22px;
    left: 22px;
    top: 46px;
    z-index: 9;
  }
  input {
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.08) !important;
    border: 0 !important;
    margin-bottom: 30px;
    height: 60px;
    padding-left: 60px !important;
    color: #343d48;
    opacity: 1;
    font-weight: 500;
    @media (max-width: 768px) {
    }
    &:focus {
      border: 0;
      box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.08);
    }

    &:placeholder {
      font-size: 16px;
      color: #343d48;
      opacity: 1;
    }
    
  }
  
  .input-icon {
    left: 10px !important;
    right: auto;
    top: 7px !important;
    height: 46px !important;
    svg {
      color: #ececec;
      width: 24px;
      height: 30px;
    }
  }
`;
export { DiscountLabel };

export default BannerWrapper;

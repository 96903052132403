import React, { Fragment, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import  firebase from "@firebase/app";
import "@firebase/firestore";
import "@firebase/storage";
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
// import DatePicker from 'common/src/components/DatePicker';
import Select from 'common/src/components/Select';

import Heading from 'common/src/components/Heading';
import Input from 'common/src/components/Input';
import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../Particle';
import { Icon } from 'react-icons-kit';
import {user} from 'react-icons-kit/fa/user'
import firestore from "../../../firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from 'ckeditor4-react';
import BannerWrapper, { DiscountLabel, EmailInputWrapper, NameInputWrapper,SearchWrapper }  from './bannerSection.style';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
}) => {
  // const Mail = "common/src/assets/image/app/mail.svg"
  const DateForArticle =(date) => {
  //   const [startDate, setStartDate] = useState(new Date());
  //  const handlingdate = date => {
  //   setStartDate({
  //     date
  //   });
     
  //     handleOnChangeDate(date)
  //   } 
    return (
      <DatePicker
        selected={state.ArticleDate}
        onChange={handleOnChangeDate}
        // placeholderText="Select the Article Date"
      />
    );
  };
  const [state, setState] = useState({
    copy:'',
    summary : '',
    title:'',
    AuthorJob: '',
    AuthorName: '',
    Authorcompany:'',
    description:'',
    BannerImg:'',
    ArticleDate:'',
    ArticleCategory:''
  });
  var copy ='';
  var summary = '';
  var title ='';
  var AuthorJob ='';
  var AuthorName ='';
  var Authorcompany = '';
  var ArticleCategory ='';
  var ArticleDate = null;
  var notification = false;
  var newDate = "";
  var options =[
    {
   label: 'Career-Tips' ,
   value: 'Career-Tips'
    }, 
    {
      label: 'Stories' ,
      value: 'Stories'
       }, 
       {
        label: 'Resources' ,
        value: 'Resources'
         }, 
  ]
  const handleOnChangeName = event => {
    // console.log(event)
    setState({
      ...state,
      AuthorName: event,
    });
  };
  const handleOnChangeDate = date => {
   ArticleDate = date
    setState({
      ...state,
      ArticleDate: date,
    });
  };
  const handleOnChangeTitle = event => {
    setState({
      ...state,
      title: event,
    });
  };
  const handleOnChangesummary = event => {
    setState({
      ...state,
      summary: event,
    });
  };
  const handleOnChangeCopy = event => {
    setState({
      ...state,
      copy: event,
    });
  };
  const handleOnChangeAuthorJob = event => {
    setState({
      ...state,
      AuthorJob: event,
    });
  };
  const handleonChangeAuthorCompany = event => {
    // console.log(event)
    setState({
      ...state,
      Authorcompany: event,
    });
  };
  const handleonChangeoptions = event => {
    console.log(event)
    // console.log(ArticleCategory)
    setState({
      ...state,
      ArticleCategory: event.value,
    });
  };
  const handleonChangeImage = event => {
    event.persist()
    // console.log(event)
    var file = event.target.files[0]
    var ref = firebase.storage().ref('blogs/'+state.title+file.name); 
    ref.put(file).then(function(result) {
      ref.getDownloadURL().then(function(url) {
        setState({
      ...state,
      BannerImg: url,
    });
      })
    });
    // setState({
    //   ...state,
    //   Authorcompany: event,
    // });
  };
  const handleSearchForm = event => {
    console.log(state)
    event.preventDefault();

    if ((state.AuthorName !== '') && (state.BannerImg !== '') && (state.Authorcompany !== '') && (state.title !== '') && (typeof(state.description) !== 'undefined') ) {

      const db = firebase.firestore();
       db.collection("BlogsDetails").add({
        AuthorName: state.AuthorName,
        AuthorJob: state.AuthorJob,
        Authorcompany:state.Authorcompany,
        Title:state.title,
        ArticleCopy:state.copy,
        ArticleSummary:state.summary,
        Articlepost:state.description,
        ArticleDate:state.ArticleDate,
        BannerImg:state.BannerImg,
        ArticleCategory:state.ArticleCategory,
        views:25,
        timestamp:firebase.firestore.FieldValue.serverTimestamp()
      }).then(() =>{
        notification = true;
        AuthorJob= AuthorName = Authorcompany = title = description = copy = summary =''
        setState({
          ...state,
          copy:'',
          summary : '',
          title:'',
          AuthorJob: '',
          AuthorName: '',
          Authorcompany:'',
          description:'',
          ArticleCategory:'',
        });
        alert('New Blog Post is Submitted')
      }) 
    
    } else {
      alert("Please fill all the required fields")
      console.log('Please fill this field.');
    }
  };

 
   var rteChange = (evt) => {
     //console.log(evt.editor.getData())
      var d1 = evt.editor.getData()
      setState({
        ...state,
        description: d1
      });
    }
  return (
    <BannerWrapper>
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={
                <Heading
                  content="Submit a Blog"
                  {...title}
                />
              }

            />
            <Fade up delay={100}>
              <div>
               <Text content= {
                 notification ? <NameInputWrapper style={{backgroundColor:'red', opacity:'0.8', color:'black'}}>
              Your Form is Submitted
              </NameInputWrapper> : '' 
              } />

              </div>
            
            <form onSubmit={handleSearchForm}>
            <NameInputWrapper>
            <Input
                inputType="text"
                icon={<Icon icon={user} />}
                placeholder="Full Name of Author"
                value={AuthorName}
                iconPosition="left"
                aria-label="name"
                onChange={handleOnChangeName}
              />
            </NameInputWrapper>
            <NameInputWrapper>
              <input type="file" onChange={handleonChangeImage}/>
            </NameInputWrapper>
            <NameInputWrapper>
            <Input
                inputType="text"
                icon={<Icon icon={user} />}
                placeholder="Author Job Title"
                value={AuthorJob}
                iconPosition="left"
                aria-label="name"
                onChange={handleOnChangeAuthorJob}
              />
            </NameInputWrapper>
            <NameInputWrapper>
            <Input
                inputType="text"
                icon={<Icon icon={user} />}
                placeholder="Author Company"
                value={Authorcompany}
                iconPosition="left"
                aria-label="name"
                onChange={handleonChangeAuthorCompany}
              />
            </NameInputWrapper>
            <NameInputWrapper>
            <Select
                labelText="Select the Article Category"
                options={options}
                // value={ArticleCategory}
                placeholder="Career-Tips"
                // className="domain_search_select"
                aria-label="select options"
                onChange={handleonChangeoptions}
                
              />
            </NameInputWrapper>
            <NameInputWrapper>
            <Input
                inputType="text"
                icon={<Icon icon={user} />}
                placeholder="Article Title"
                value={title}
                iconPosition="left"
                aria-label="name"
                onChange={handleOnChangeTitle}
              />
            </NameInputWrapper>
{/*             
              <DatePicker className="datepicker" labelText="Select Date on which artcicle to be posted" labelPosition="top"
                placeholder="Select date"
                value={ArticleDate}
                showClearDate={true}
                date={ArticleDate} // momentPropTypes.momentObj or null
                onDateChange={handleOnChangeDate}/> */}
            {/* <input
                type="date"
                // icon={<Icon icon={user} />}
                placeholder="Select date on which artcicle to be posted"
                value={ArticleDate}
                // iconPosition="left"
                aria-label="name"
                onChange={handleOnChangeDate}
              /> */}
              <NameInputWrapper>
              <DateForArticle onChange={handleOnChangeDate}/>
              </NameInputWrapper>
            <NameInputWrapper>
            <Input
                inputType="textarea"
                icon={<Icon icon={user} />}
                placeholder="Article Summary (word limit max:100 )"
                value={summary}
                iconPosition="left"
                aria-label="name"
                onChange={handleOnChangesummary}
              />
            </NameInputWrapper>
            <NameInputWrapper>
            <Input
                inputType="textarea"
                icon={<Icon icon={user} />}
                placeholder="Article Copy (word limit max:30)"
                value={copy}
                iconPosition="left"
                aria-label="name"
                onChange={handleOnChangeCopy}
              />
            </NameInputWrapper>

            <CKEditor onChange={rteChange}
                data="<p>Some initial data</p>"
                
            />

            <div>
            <Button  title="SUBMIT" onClick={handleSearchForm} {...btnStyle} />
            </div>
            </form>
            </Fade>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '100%'],
  },
  title: {
    fontSize: ['26px', '34px', '42px', '55px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['5px', '10px'],
    lineHeight: '1.31',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '2.1',
    mb: '5px',
  },
  btnStyle: {
    type: 'button',
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    mt: '22px',
    colors: 'primaryWithBg',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#10AC84',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: '14px',
    color: '#0f2137',
    mb: 0,
    as: 'span',
  },
};

export default BannerSection;
